import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MagnifyingGlass } from 'react-loader-spinner';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { AddContact } from 'core/useCases/contacts/contactActions';
import Contact from 'core/models/contact';
import { POST } from 'utils/http';
import { CloseModal } from 'core/useCases/modal/modalAction';
import {
    SFileInputContainer,
    SFileInputTitle,
    SHelpAnchor,
    SHelpContainer,
    SHelpDescriptionContainer,
    SHelpIconContainer,
    SHelpTitle,
    SImportLoading,
    SNeedHelpContainer,
} from './styles';
import { ReactComponent as Google } from 'images/icons/Google.svg';
import { ReactComponent as Import } from 'images/icons/Import.svg';
import { ReactComponent as Linkedin } from 'images/icons/Linkedin.svg';
import { ReactComponent as Outlook } from 'images/icons/Outlook.svg';
import ContactSelector from 'components/Contact/ContactSelector';
import { useTheme } from 'styled-components';

export type ImportedContact = {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    mobileNumber?: string;
    email?: string;
    comments?: string;
    company?: string;
    job?: string;
    civility: { Male: 'male'; Female: 'female' };
};

type Inputs = {
    files: FileList;
};

type ImportContactsResponse = {
    importedContacts: ImportedContact[];
};

enum ImportStatus {
    DONE = 'DONE',
    LOADING = 'LOADING',
    READY = 'READY',
}

const importContactsRequest = async (formData: FormData): Promise<ImportContactsResponse> => {
    const response = (await POST<ImportContactsResponse>(
        `${BACKEND_URL}/api/contacts/import`,
        formData,
    )) as ImportContactsResponse;

    return response;
};

type CreateContactsResponse = {
    createdContacts: Contact[];
};

const createContactsRequest = async (contacts: ImportedContact[]): Promise<CreateContactsResponse> => {
    const response = (await POST<CreateContactsResponse>(`${BACKEND_URL}/api/contacts`, {
        contacts,
    })) as CreateContactsResponse;

    return response;
};

type ContactsFormProps = {
    closeModal(): void;
};

const ContactsForm: FC<ContactsFormProps> = ({ closeModal }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const [importedContacts, setImportedContacts] = useState<ImportedContact[]>([]);
    const [importStatus, setImportStatus] = useState<ImportStatus>(ImportStatus.READY);

    const helpers = [
        {
            components: {
                s: <SHelpTitle />,
            },
            i18nKey: 'contact.import.linkedin-help',
            icon: <Linkedin />,
        },
        {
            components: {
                a: (
                    <SHelpAnchor href="https://contacts.google.com" rel="noopener noreferrer" target="_blank">
                        contacts.google.com
                    </SHelpAnchor>
                ),
                s: <SHelpTitle />,
            },
            i18nKey: 'contact.import.google-help',
            icon: <Google />,
        },
        {
            components: {
                s: <SHelpTitle />,
            },
            i18nKey: 'contact.import.outlook-help',
            icon: <Outlook />,
        },
        {
            components: {
                s: <SHelpTitle />,
            },
            i18nKey: 'contact.import.import-help',
            icon: <Import />,
        },
    ];

    // form
    const methods = useForm<Inputs>();
    const { handleSubmit, register } = methods;

    const onSubmitImport = handleSubmit(async (values: Inputs) => {
        const formData = new FormData();
        formData.append('file', values.files[0]);

        try {
            setImportStatus(ImportStatus.LOADING);
            const response = await importContactsRequest(formData);
            // for animation visibility
            await new Promise((resolve) => setTimeout(resolve, 500));
            setImportedContacts(response.importedContacts);
            setImportStatus(ImportStatus.DONE);
        } catch {
            toast.error(t('contact.import.error'));
        }
    });

    const onSubmitCreate = async (selectedContacts: ImportedContact[]) => {
        try {
            const { createdContacts } = await createContactsRequest(selectedContacts);
            for (const contact of createdContacts) {
                dispatch(AddContact(contact));
            }
            toast.success(t('contact.import.success', { count: selectedContacts.length }));
            dispatch(CloseModal());
        } catch {
            toast.error(t('contact.import.error'));
            dispatch(CloseModal());
        }
    };

    switch (importStatus) {
        case ImportStatus.DONE:
            return (
                <ContactSelector
                    cancel={() => dispatch(CloseModal())}
                    selectableContacts={importedContacts}
                    submit={onSubmitCreate}
                />
            );
        case ImportStatus.LOADING:
            return (
                <SImportLoading>
                    <MagnifyingGlass
                        ariaLabel="MagnifyingGlass-loading"
                        color={theme.color.secondary.main}
                        height="80"
                        width="80"
                        visible
                    />
                    <Trans i18nKey="contact.import.loading" />
                </SImportLoading>
            );
        default:
            return (
                <FormProvider {...methods}>
                    <form onSubmit={onSubmitImport}>
                        <SFileInputTitle>{t('contact.import.title')}</SFileInputTitle>
                        <SFileInputContainer>
                            <input
                                {...register('files', {
                                    required: t('common.required-field') as string,
                                })}
                                accept=".csv"
                                type="file"
                            />
                        </SFileInputContainer>
                        <SFileInputTitle>{t('contact.import.need-help')}</SFileInputTitle>
                        <SNeedHelpContainer>
                            {helpers.map((helper) => (
                                <SHelpContainer key={helper.i18nKey}>
                                    <SHelpIconContainer>{helper.icon}</SHelpIconContainer>
                                    <SHelpDescriptionContainer>
                                        <Trans components={helper?.components} i18nKey={helper.i18nKey} />
                                    </SHelpDescriptionContainer>
                                </SHelpContainer>
                            ))}
                        </SNeedHelpContainer>
                        <BaseModal.BottomActions>
                            <Button onClick={() => closeModal()} variant="text">
                                {t('common.cancel')}
                            </Button>
                            <Button submit>{t('contact.import.label')}</Button>
                        </BaseModal.BottomActions>
                    </form>
                </FormProvider>
            );
    }
};

export default ContactsForm;
